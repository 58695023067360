@use 'utilities';
// DIRECTIVE SLIDER
.dirslider_divSlides {
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  display: inline-block;
  transition:
    transform 0.4s ease-out,
    margin-top 0.4s ease-out;

  img {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
.dirslider_slide {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.dirslider_noTransition {
  transition: none !important;
}
.dirslider_divBullets {
  width: 100%;
  padding: 1em 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  // bottom: 30px;
}
.dirslider_bullet {
  display: inline-block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: var(--color-gray-tint);
  margin-right: 6px;
  cursor: pointer;
}
.dirslider_activeBullet {
  background-color: var(--color-gray);
}
.dirslider_bullet:last-child {
  margin: 0;
}
.dirslider_btLeft {
  position: absolute;
  z-index: 1;
  left: 0;
  font-size: 4em;
  padding: 0 0.5em;
  cursor: pointer;
  color: var(--color-white);
  opacity: 0;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  transition: opacity 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  @extend .small-clickable;
  background: url('../assets/svg/arrowLeft.svg');
  filter: brightness(10) drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}
.dirslider_btRight {
  position: absolute;
  z-index: 1;
  right: 0;
  padding: 0 0.5em;
  font-size: 4em;
  cursor: pointer;
  color: var(--color-white);
  opacity: 0;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  transition: opacity 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  @extend .small-clickable;
  background: url('../assets/svg/arrowRight.svg');
  filter: brightness(10) drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .dirslider_btControls {
    display: none;
  }
  .dirslider_bullet {
    width: 16px;
    height: 16px;
  }
}
