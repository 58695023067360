// **********************************
// * GLOBAL RULES FOR ALL EVS-FORMS *
// **********************************

@mixin label-up {
  font-size: 14px !important;
  margin-top: -8px !important;
}

.evs-form,
.evs-dark-form {
  div {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 16px;

    input::placeholder,
    textarea::placeholder {
      color: transparent;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='file'],
    input[type='number'],
    input[type='tel'],
    input[type='date'],
    input[type='time'],
    input[type='month'],
    input[type='week'],
    textarea,
    select,
    .fake-field,
    .fake-field-ui {
      width: 100%;
      height: 55px;
      border: 2px solid var(--color-neutral-400);
      background-color: transparent;
      padding: 22px 24px 10px 24px;
      border-radius: 16px;
      transition: border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      outline: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
    .fake-field {
      position: absolute;
      z-index: 2;
      height: 55px;
      pointer-events: none;
      cursor: pointer;
    }
    .fake-field-ui {
      height: auto;
      padding: 16px 24px;
      position: initial;
      min-height: 55px;
      pointer-events: initial;
      cursor: initial;
    }
    select {
      /* reset */
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;

      background-image:
        linear-gradient(45deg, transparent 50%, var(--color-neutral-400) 50%),
        linear-gradient(135deg, var(--color-neutral-400) 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 20px) 22px,
        calc(100% - 14px) 22px,
        calc(100% - 2.5em) 0.5em;
      background-size:
        6px 6px,
        6px 6px,
        1px 32px;
      background-repeat: no-repeat;

      padding: 16px 24px;

      option[disabled] {
        color: var(--color-neutral-400);
      }
    }
    select.ng-pristine {
      color: var(--color-neutral-400);
    }
    .evs_autocomplete input[type='text'],
    .evs_autocomplete .evs_options div {
      margin-bottom: 0;
    }
    label {
      position: absolute;
      z-index: 1;
      color: var(--color-neutral-400);
      top: 19px;
      left: 26px;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 18px;
      @media screen and (max-width: 767px) {
        width: 220px;
      }
    }
    .label-up {
      @include label-up;
    }
    .evs_autocomplete label.label-up {
      margin-left: 0;
    }

    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .label-radio {
      color: var(--color-main-text);
      position: initial;
      margin-left: 8px;
      pointer-events: initial;
      animation: none;
      font-size: 16px !important;
    }

    // -----------
    // | FIX FOR |
    // -----------

    // UPLOAD FILES (check on other browsers)

    input[type='file'] {
      position: relative;
      z-index: 1;
      opacity: 0;
      width: calc(100% - 48px);
      height: 55px;
      cursor: pointer;
    }

    .evs_delete {
      cursor: pointer;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 12px;
      bottom: 16px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      color: var(--color-gray-tint);
      padding: 2px;
      opacity: 1;
    }
    .evs_delete:hover {
      background-color: var(--color-muted);
    }

    // input[type="file"] + label, input[type="file"] + .label-up {
    //   margin-top: -30px;
    // }
    // input[type="file"] ~ span.field-error {
    //   margin-top: -20px;
    // }

    // INPUT COLOR
    input[type='color'] {
      display: flex;
      justify-items: center;
      min-height: 40px;
    }
    input[type='color'] + label,
    input[type='color'] + .label-up {
      margin-top: -20px;
    }
    input[type='color'] ~ span.field-error {
      margin-top: 20px;
    }

    // INPUT DATE
    input[type='date'] + label {
      font-size: 14px;
      margin-top: -8px;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 17px;
      right: 14px;
      opacity: 0.45;
    }

    // // INPUT MONTH
    // input[type="month"] + label {
    //   margin-top: -25px;
    // }

    // // INPUT WEEK
    // input[type="week"] + label {
    //   margin-top: -30px;
    // }

    // // INPUT TIME
    // input[type="time"] + label {
    //   margin-top: -25px;
    // }

    // INPUT RANGE
    input[type='range'] {
      display: flex;
      justify-items: center;
      min-height: 20px;
    }
    input[type='range'] + label {
      margin-top: -18px;
    }
    input[type='range'] ~ span.field-error {
      margin-top: 20px;
    }
  }
  div:focus-within label {
    @include label-up;
  }
  div:focus-within .evs_autocomplete label {
    margin-left: 0;
  }
  div:focus-within .evs_autocomplete.select label {
    margin-left: 0 !important;
    margin-top: 0 !important;
    font-size: 16px !important;
  }

  div textarea {
    padding-top: 28px;
    resize: none;
    height: initial !important;
  }

  input:not([type='color']).ng-invalid.ng-touched,
  textarea.ng-invalid.ng-touched,
  select.ng-invalid.ng-touched {
    border-color: var(--color-red-500);
  }
  input:not([type='color']).ng-valid,
  textarea.ng-valid,
  select.ng-valid {
    // border-color: var(--color-green-500);
  }

  div:focus-within input[type='text'],
  div:focus-within input[type='email'],
  div:focus-within input[type='password'],
  div:focus-within input[type='file'],
  div:focus-within input[type='number'],
  div:focus-within input[type='tel'],
  div:focus-within input[type='date'],
  div:focus-within input[type='time'],
  div:focus-within input[type='month'],
  div:focus-within input[type='week'],
  div:focus-within textarea,
  div:focus-within select,
  .fake-input:hover {
    border-color: var(--color-main-text);
  }

  div:last-child {
    margin-bottom: 0 !important;
    .field-error {
      margin-bottom: 0 !important;
    }
  }

  // fake input for toggle
  .fake-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--color-gray-tint);
    background-color: transparent;
    padding: 25px 0 10px 4px;
    outline: 0;
    .label-up {
      margin-top: -18px;
    }
  }

  // **********
  // * ERRORS *
  // **********

  div.field-error {
    font-size: 14px;
    color: var(--color-red-500);
    margin: -8px 0 24px 16px;
  }
}

.evs-dark-form {
  div {
    color: var(--color-white);
    label {
      color: var(--color-gray-tint);
    }
    .label-up,
    .fake-input > label {
      color: var(--color-white);
    }
    span.field-error {
      color: var(--color-warning-tint);
    }
    .evs_autocomplete .evs_options div {
      color: var(--color-font);
    }
  }
  div:focus-within label {
    color: var(--color-white);
  }
  input:not([type='color']).ng-invalid.ng-touched,
  textarea.ng-invalid.ng-touched,
  select.ng-invalid.ng-touched {
    border-bottom: 2px solid var(--color-warning-tint);
  }
  div:focus-within input[type='text'],
  div:focus-within input[type='email'],
  div:focus-within input[type='password'],
  div:focus-within input[type='file'],
  div:focus-within input[type='number'],
  div:focus-within input[type='tel'],
  div:focus-within input[type='date'],
  div:focus-within input[type='time'],
  div:focus-within input[type='month'],
  div:focus-within input[type='week'],
  div:focus-within textarea,
  div:focus-within select,
  .fake-input:hover {
    border-bottom: 2px solid var(--color-white);
  }
  blockquote {
    background-color: var(--color-light);
    color: var(--color-font);
  }
}

// FUCKING CHROME (to be done for other browsers)
// FUCKING CHROME (to be done for other browsers)
// FUCKING CHROME (to be done for other browsers)

.evs-form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: var(--color-light) !important;
    -webkit-box-shadow: 0 0 0 30px var(--color-light) inset !important;
  }
}
.evs-dark-form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: var(--color-font) !important;
    -webkit-box-shadow: 0 0 0 30px var(--color-font) inset !important;
  }
}

// **************************************
// * CUSTOM FOR LAB OR THINGS LIKE THIS *
// **************************************

.customForms {
  .uploadBtn {
    display: block;
    border: 2px solid var(--color-gray);
    color: var(--color-gray);
    background-color: var(--color-white);
    padding: 8px 20px;
    // border-radius: 8px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 700;
  }
  textarea {
    width: 100%;
    height: 200px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 0.6em;
    transition: all 0.3s cubic-bezier(0.1, 0.8, 0.2, 1.6);
    margin: 0 0 0.5em 0;
    line-height: 1.4em;
  }

  .textareaEditable:hover textarea {
    background: none repeat scroll 0 0 #ffffff;
    outline-width: 0;
    height: 400px;
  }

  // form, input[type]:not([type=search]):not([type=url]):not([type=hidden]):not([type=color]) {
  input[type]:not([type='search']):not([type='url']):not(
      [type='hidden']
    )::placeholder {
    font-size: 0.9em !important;
  }

  .btnSubmit {
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--color-white);
    font-weight: 700;
    background-color: #5cb85c;
    border-color: #4cae4c;

    &:hover {
      background-color: darken(#5cb85c, 10%);
      border-color: darken(#4cae4c, 12%);
    }
    &:active {
      background-color: darken(#5cb85c, 17%);
      border-color: darken(#4cae4c, 25%);
    }
    &:disabled {
      background-color: lighten(#5cb85c, 10%);
      border-color: lighten(#4cae4c, 12%);
    }
  }

  .btnReset {
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--color-white);
    font-weight: 700;
    background-color: #dc3545;
    border-color: #cc2b35;

    &:hover {
      background-color: darken(#dc3545, 10%);
      border-color: darken(#cc2b35, 12%);
    }
    &:active {
      background-color: darken(#dc3545, 17%);
      border-color: darken(#cc2b35, 25%);
    }
    &:disabled {
      background-color: lighten(#dc3545, 10%);
      border-color: lighten(#cc2b35, 12%);
    }
  }

  label {
    display: inline-block;
    font-size: 1.1em;
    color: #333;
    margin: 0 5px 5px 0;
  }

  .formControl {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px;
    font-size: 1.1em;
    color: #555;
    background-color: var(--color-white);
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    .text-input-md {
      margin: 0;
    }
  }

  .small {
    max-width: 300px;
  }

  .alert {
    color: red;
    font-size: 0.9em;
    text-align: right;
    padding-top: 4px;
    padding-right: 4px;
  }

  .select-style {
    border: 1px solid #ccc;
    width: 100%;
    height: 34px;
    padding: 6px;
    font-size: 1.1em;
    color: #555;
    background-color: var(--color-white);
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border-radius: 4px;
    overflow: hidden;
    background: var(--color-white);
  }

  .select-style select:focus {
    outline: none;
  }
}
