body .pac-container {
    background: white !important;
    border-radius: 16px !important;
    border: unset !important;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
    padding: 0 !important;
    margin-top: 24px !important;
    z-index: 1000 !important;

    @media screen and (max-width: 778px) {
        left: 12px !important;
        width: 93vw !important;
        box-shadow: none !important;
        border-radius: none !important;
    }

    @media screen and (min-width: 778px) {
        width: 600px !important;
        margin-left: -8px !important;
    }

    .pac {
        &-item {
            position: relative;
            display: flex !important;
            align-items: center !important;
            gap: 8px !important;
            padding: 12px 16px !important;
            cursor: pointer !important;
            transition: background-color 0.2s ease !important;
            background-color: #ffffff !important;

            &:not(:last-child)::before {
                position: absolute;
                content: "";
                bottom: 0;
                width: calc(100% - 16px* 2);
                height: 2px;
                left: 50%;
                transform: translateX(-50%);
                pointer-events: none;
                box-sizing: border-box;
                border-bottom: 1px solid #DFE2E9 !important;
            }

            &:hover {
                background-color: var(--Neutral-4, #F2F5F9) !important;
            }

            &-query {
                color: var(--Neutral-1, #5F6677) !important;
                font-size: 16px !important;
                line-height: 24px !important;
            }

            span:last-child {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-icon {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2769 7.19 8.88 8.58687 8.88 10.31C8.88 12.0331 10.2769 13.43 12 13.43Z" stroke="%235F6677" stroke-width="1.5" /><path d="M3.62 8.49C5.59 -0.169998 18.42 -0.159998 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39 20.54C5.63 17.88 2.47 13.57 3.62 8.49Z" stroke="%235F6677" stroke-width="1.5" /></svg>') !important;
            margin: unset !important;
            flex-shrink: 0 !important;
        }

        &-matched {
            color: var(--Dark, #212529) !important;
            font-weight: 600 !important;
        }
    }
}