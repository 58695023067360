
// EVS LAB
.evslab_title {
  font-size: 2.1em;
  font-weight: 700;
}
.evslab_subtitle {
  font-size: 1.1em;
  font-weight: normal;
  margin: 0;
}
.evslab_contentTitle {
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: .2em;
}
.evslab_contentText {
  margin-bottom: .5em;
  font-size: 1em;
}

.evslab_cadreDemo {
  border-radius: 4px;
  padding: 1vw;
  @media screen and (max-width: 1280px) {
    padding: 1.5vw;
  }
  @media screen and (max-width: 768px) {
    padding: 2vw;
  }
  margin: 0 auto 3em auto;
  width:100%;
  background:var(--color-white);
}
.evslab_cadreDemo:last-child {
  margin-bottom: 0;
}

.evslab_hr {
  width: 100%;
  background-color: transparent !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .4), rgba(0, 0, 0, 0)) !important;
  margin: 2em auto;
}

video {
  width: 100%;
}

// INFORMATION
.evslab_info {
  padding: 1.5rem;
  border: 1px solid lightgray;
  border-left-width: 1rem;
  border-radius: .5rem;
  font-size: 1em;
  line-height: 1.8em;
}

.evslab_info_success {
  border-left-color: #28a745;
}
.evslab_info_danger {
  border-left-color: #d9534f;
}
.evslab_info_primary {
  border-left-color: #007bff;
}
.evslab_info_secondary {
  border-left-color: #6c757d;
}
.evslab_info_warning {
  border-left-color: #ffc107;
}
.evslab_info_info {
  border-left-color: #17a2b8;
}

.evslab_border_danger {
  border-color: #d9534f;
  border-width: 1.5em;
}

// PRE CODE
.evslab_pre {
  margin: 0;
  line-height: 1.5em;
  font-weight: 700;
  font-size: 1em !important;
  padding: .5em 1em;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 4px;
}
.code {
  background-color: #f8f9fa;
}
.selectable {
  cursor: text;
}

// FANCY SHADOW
.fancy_shadow {
  position: relative;
  box-shadow: 0 0 8px rgba(0, 0, 0, .4), 0 0 40px rgba(0, 0, 0, .1) inset;
  transition: box-shadow .3s ease-out;
}
.fancy_shadow:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, .8), 0 0 40px rgba(0, 0, 0, .2) inset;
}
.fancy_shadow:before, .fancy_shadow:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, .3);
  top: 50%;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-radius: 100px / 10px;
}

// fix for topnavLab
.evslab_ionheader {
  background-color: #333;
  min-height: 50px;
  pointer-events: none;
  #menu * {
    pointer-events: initial;
  }
  @media screen and (max-width : 1099px) {
    pointer-events: initial;
  }
}