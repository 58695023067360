// $base-color: #E6E6E6;
// $shine-color: #f0f0f0;
// $light-base-color: #f0f0f0;
// $light-shine-color: #fafafa;
// $animation-duration: 2.6s;

// @mixin shimmer {
//   background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
//   background-size: 100vw;
//   animation: shimmer-animation $animation-duration infinite linear;
// }
// .shimmer {
//   @include shimmer;
// }
// @mixin light-shimmer {
//   background-image: linear-gradient(90deg, $light-base-color 0px, $light-shine-color 40px, $light-base-color 80px);
//   background-size: 100vw;
//   animation: shimmer-animation $animation-duration infinite linear;
// }
// .light-shimmer {
//   @include light-shimmer;
// }

// @keyframes shimmer-animation {
//   0% {
//     background-position: -100vw;
//   }
//   40%, 100% {
//     background-position: 100%;
//   }
// }

// .skeleton {

//   h3 {
//     height: 22px;
//     width: 200px;
//   }

//   h4 {
//     height: 20px;
//     width: 200px;
//   }

//   h5 {
//     height: 20px;
//     width: 120px;
//   }

//   p {
//     height: 18px;
//     width: 200px;
//   }

//   @for $i from 0 through 500 {
//     .circle-#{$i} {
//       width: #{$i}px;
//       height: #{$i}px;
//       border-radius: 50%;
      
//       animation: loading 1.7s infinite linear;
//       background: #F1F2F3;
//       background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//       background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//       background-repeat: no-repeat;
//     }

//     .rectangle-#{$i}h {
//       height: #{$i}px;
//       width: 100%;

//       animation: loading 1.7s infinite linear;
//       background: #F1F2F3;
//       background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//       background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//       background-repeat: no-repeat;
//     }

//     .square-#{$i} {
//       height: #{$i}px;
//       width: #{$i}px;

//       animation: loading 1.7s infinite linear;
//       background: #F1F2F3;
//       background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//       background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//       background-repeat: no-repeat;
//     }
//   }

// }

// // Animations 

// .skeleton {
//   h3, h4, h5, p, .serie-title, .serie-progress-bar {
//     animation: loading 1.7s infinite linear;
//     background: #F1F2F3;
//     background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//     background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
//     background-repeat: no-repeat;
//   }
// }

// @keyframes loading {
//   0% {
//     background-position: -100px;
//   }

//   100% {
//     background-position: 200px;
//   }
// }


$dark-base-color: rgb(215, 215, 215);
$dark-shine-color: rgb(225, 225, 225);
$base-color: rgb(230, 230, 230);
$shine-color: rgb(240, 240, 240);
$light-base-color: rgb(240, 240, 240);
$light-shine-color: rgb(250, 250, 250);
$animation-duration: 2.6s;

@mixin shimmer {
  // background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-color: $base-color;
  position: relative;
  overflow: hidden;
  
}
.shimmer {
  @include shimmer;
}
.shimmer::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, .3);
  height: 100%;
  transform: skew(5deg);
  animation: shimmer-animation infinite 2s ease-in-out;
}

@mixin dark-shimmer {
  background: $dark-base-color;
  position: relative;
  overflow: hidden;
}
.dark-shimmer {
  @include dark-shimmer;
}
.dark-shimmer::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  background: rgba(255, 255, 255, .3);
  height: 100%;
  transform: skew(5deg);
  animation: shimmer-animation infinite 2s ease-in-out;
}

@mixin light-shimmer {
  background: $light-base-color;
  position: relative;
  overflow: hidden;
}
.light-shimmer {
  @include light-shimmer;
}
.light-shimmer::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  background: rgba(255, 255, 255, .3);
  height: 100%;
  transform: skew(5deg);
  animation: shimmer-animation infinite 2s ease-in-out;
}

@keyframes shimmer-animation {
  from {
    transform:skew(30deg) translateX(-100%);
  }
  
  to{
    transform:skew(30deg) translateX(100%);
  }
}

.skeleton {

  h3 {
    height: 22px;
    width: 200px;
  }

  h4 {
    height: 20px;
    width: 200px;
  }

  h5 {
    height: 20px;
    width: 120px;
  }

  p {
    height: 18px;
    width: 200px;
  }

  @for $i from 0 through 500 {
    .circle-#{$i} {
      width: #{$i}px;
      height: #{$i}px;
      border-radius: 50%;
      
      animation: loading 1.7s infinite linear;
      background: #F1F2F3;
      background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
      background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
      background-repeat: no-repeat;
    }

    .rectangle-#{$i}h {
      height: #{$i}px;
      width: 100%;

      animation: loading 1.7s infinite linear;
      background: #F1F2F3;
      background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
      background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
      background-repeat: no-repeat;
    }

    .square-#{$i} {
      height: #{$i}px;
      width: #{$i}px;

      animation: loading 1.7s infinite linear;
      background: #F1F2F3;
      background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
      background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
      background-repeat: no-repeat;
    }
  }

}

// Animations 

.skeleton {
  h3, h4, h5, p, .serie-title, .serie-progress-bar {
    animation: loading 1.7s infinite linear;
    background: #F1F2F3;
    background-image: -webkit-linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
    background-image: linear-gradient(to right, #F1F2F3 0%, #E8E9EC 20%, #F1F2F3 40%, #F1F2F3 100%);
    background-repeat: no-repeat;
  }
}

@keyframes loading {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 200px;
  }
}