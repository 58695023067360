ion-header {
  z-index: 2000;
}
ion-header,
ion-footer {
  user-select: none;
}

// // ion-header/footer nav rules mobile/desktop
// ion-footer {
//   display: none;
// }
// @media screen and (max-width: 768px) {
//   ion-header {
//     display: none;
//   }
//   ion-footer {
//     display: initial;
//   }
//   .ion-content-container {
//     padding: 12px 8px;
//   }
// }

// page content padding
.ion-content-container {
  padding: 24px 16px;
}
