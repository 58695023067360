.toggle {
  display: inline-block;
  width: 55px;
  height: 25px;
  position: relative;
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    width: 55px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 20px;
    top: -18px;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  input:checked + label:before {
    content: attr(data-true-value);
    font-size: 11px;
    position: absolute;
    top: 6px;
    left: 6px;
    font-weight: 700;
    color: var(--color-white);
  }

  label:before {
    content: attr(data-false-value);
    font-size: 11px;
    position: absolute;
    top: 6px;
    right: 6px;
    font-weight: 700;
    color: var(--color-white);
  }

  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 19px;
    background: var(--color-white);
    border-radius: 40px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #5cb85c;
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 25px;
  }
}
