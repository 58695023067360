@use 'utilities';
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Italic.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

html, .ios, .md {
  --ion-text-color: var(--color-font);
  --ion-default-font: "Ubuntu", "Helvetica Neue", sans-serif;
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

img {
  pointer-events: none;
}

// global headers
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  font-family: inherit;
  line-height: 1.15;
  small {
    // headers + secondary text
    font-size: 65%;
    display: block;
    text-decoration: none;
  }
}

// each headers
h1 {
  font-size: 48px;
  margin-bottom: 24px;
  // @media screen and (max-width: 767px) {
  //   font-size: 40px;
  //   line-height: 105%;
  // }
}
h2 {
  font-size: 40px;
  margin-bottom: 16px;
}
h3 {
  font-size: 32px;
  margin-bottom: 8px;
}
h4 {
  font-size: 24px;
  margin-bottom: 8px;
}
h5 {
  font-size: 18px;
  margin-bottom: 8px;
}
h6 {
  font-size: 16px;
  margin-bottom: 8px;
}

// callout
.callout {
  font-size: 18px;
}

// subtitle

.subtitle {
  font-size: 18px;
  margin-bottom: 16px;
}

// paragraph
p {
  margin: 0 0 1em;
  line-height: 1.4;
}
p:last-child {margin: 0;}

// links
a {
  color: var(--color-orange-evs);
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  @extend .small-clickable;
}
a:visited {color: var(--color-orange-evs)}
a:hover {text-decoration: none}
a:active {color: var(--color-orange-evs)}

// address
address {margin-bottom: 1em;}
address:last-child {margin: 0;}

// blockquote
blockquote {
  padding: 16px;
  margin: 0 0 16px;
  border-radius: 8px;
  background-color: var(--color-neutral-50);
  // border: 1px solid var(--color-info);
  border-left: 8px solid var(--color-neutral-400);
  // citations
  footer, small {
    display: block;
    font-size: 80%;
    line-height: 1.5;
    color: var(--color-gray-62);
  }
  footer:before, small:before {
    content: "\2014 \00A0";
  }
}
.blockquote-reverse {
  border-left: 0;
  border: 1px solid var(--color-info);
  border-right: 8px solid var(--color-info);
  // citations
  p, footer, small {
    text-align: right;
  }
  footer:after, small:after {
    content: "\2014 \00A0";
  }
}
blockquote:hover {
  background-color: darken(#F7F9FB, 2%);
}
blockquote:last-child, .blockquote-reverse:last-child {
  margin-bottom: 0;
}

// small
small {
  font-size: 80%;
  font-weight: initial;
  display: block;
}

// mark
mark {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: var(--color-oyster);
}

// fieldset
fieldset {
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  min-width: 0;
  legend {
    text-transform: capitalize;
    font-weight: 700;
    padding: 0 10px;
  }
}
fieldset:last-child {
  margin-bottom: 0;
}

// table
table {
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  > thead > tr > th {
    font-weight: 700;
  }
  td, th {
    position: static;
    display: table-cell;
    float: none;
    padding: 8px;
    line-height: 1.5;
  }
}
