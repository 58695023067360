@use "utilities";
// $bg-primary: linear-gradient(90deg, #EA5B0C -0.04%, #FB8841 99.96%);
$bg-primary: var(--color-orange-500);
$txt-primary: var(--color-white);
$bg-light: var(--color-neutral-100);
$txt-light: var(--color-orange-500);

@mixin shadowed-button {
  box-shadow: inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),
              inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),
              inset 0 0 0em 0.05em rgba(255,255,255,0.12);
}
.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  touch-action: manipulation;
  background-image: none;
  font-size: 16px;
  line-height: 1em;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
  // border: 1px solid transparent;
  border-radius: 16px;
  padding: 18px 36px;
  background: $bg-primary;
  color: $txt-primary;
  user-select: none;
  outline: 0;
  cursor: pointer;
  // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1);
  @extend .small-clickable;
}

@media (hover:hover) and (pointer: fine) {
  .btn:hover {
    background: var(--color-orange-400);
  }
  .btn.light:hover {
    background: var(--color-neutral-200);
  }
}
.btn:active {
  background: var(--color-orange-600);
}
input[type="submit"].btn {
  border: none;
}
// input[type="submit"].btn.light {
//   border: 1px solid var(--color-orange-evs);
// }
input[type="submit"][disabled].btn, button[disabled].btn, button[disabled].btn:hover, button[disabled].btn:active {
  background-color: var(--color-neutral-300) !important;
  background: var(--color-neutral-300) !important;
  box-shadow: none;
  zoom: 1;
  cursor: not-allowed;
}
input[type="submit"][disabled].btn.light, button[disabled].btn.light, button[disabled].btn.light:hover, button[disabled].btn.light:active {
  background-color: var(--color-neutral-300) !important;
  background: var(--color-neutral-300) !important;
  color: var(--color-white) !important;
  box-shadow: none;
  zoom: 1;
  cursor: not-allowed;
}
.btn.center {
  display: block;
  margin: auto !important;
}

.btn.large {
  font-size: 18px;
  padding: 22px 36px;
}

.btn.small {
  font-size: 14px;
  border-radius: 12px;
  padding: 14px 24px;
  font-weight: 500;
}

.btn.xsmall {
  font-size: 12px;
  border-radius: 12px;
  padding: 12px 18px;
  font-weight: 500;
}

.btn.light {
  font-weight: 700;
  background: $bg-light;
  color: $txt-light;
  box-shadow: none;
}
.btn.light:active {
  background: var(--color-neutral-300);
}

.btn.full-width {
  width: 100%;
  @extend .clickable;
}

loading-button-comp {
  display: inline-block;
}
