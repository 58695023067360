.evs_toaster_container {
  position: absolute;
  z-index: 3060;
  top: 1em;
  left: 1em;
  right: 1em;
  bottom: 1em;
  margin: auto;
  pointer-events: none;
}
// each part
.evs_toaster_container_part {
  position: absolute;
  z-index: 3061;
  pointer-events: none;
  // temp to debug
  // padding: 5em;
  // border: 1px solid black;
}
// top left
.evs_tc_part_tl {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
// top right
.evs_tc_part_tr {
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
// bottom left
.evs_tc_part_bl {
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}
// bottom right
.evs_tc_part_br {
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
// top center
.evs_tc_part_tc {
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
// bottom center
.evs_tc_part_bc {
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.evs_toaster {
  max-width: 415px;
  border-radius: 5px;
  background-color: rgba(10, 10, 13, .8);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1);
  color: var(--color-white);
  display: flex;
  align-items: center;
  pointer-events: initial;
  padding: .6em .7em;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  font-size: .9em;
  margin: auto;
  font-family: var(--ion-font-family);
}
.evs_toaster_sticky {
  max-width: 415px;
  color: var(--color-font);
  display: flex;
  align-items: center;
  pointer-events: initial;
  opacity: 0;
  user-select: none;
  font-size: 1em;
  margin: auto;
  font-family: var(--ion-font-family);
  blockquote {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    box-shadow: 0 0 6px -1px var(--color-bali);
    padding: 15px !important;
    line-height: 1.2em !important;
  }
}
.evs_toaster_close_btn {
  float: right;
  color: var(--color-white);
  font-size: 1.6em;
  margin-left: .2em;
  // margin-top: 3px;
  pointer-events: initial;
  width: 23px;
  height: 23px;
  text-align: center;
  cursor: pointer;
}
.evs_toaster:last-child {
  margin-bottom: 0;
}
// animations
@keyframes showUp {
  from {
    opacity: 0;
    transform: translateY(-5vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
}
@keyframes showDown {
  from {
    opacity: 0;
    transform: translateY(5vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes hideLeft {
  from {opacity: 1}
  to {
    opacity: 0;
    transform: translateX(-5vw);
  }
}
@keyframes hideRight {
  from {opacity: 1}
  to {
    opacity: 0;
    transform: translateX(5vw);
  }
}
@keyframes hideUp {
  from {opacity: 1}
  to {
    opacity: 0;
    transform: translateY(-5vw);
  }
}
@keyframes hideDown {
  from {opacity: 1}
  to {
    opacity: 0;
    transform: translateY(5vw);
  }
}