// DIRECTIVE LAZY LOADING
.dirlazy_customClass {
  line-height: 0;
  border-radius: 6px;
  border: 6px solid gray;
  overflow: hidden;
}
.dirlazy_debug {
  line-height: 0;
  border-radius: 6px;
  border: 6px solid gray;
  overflow: hidden;
}