@use "variables";
// ********************************
// *         COLORS VARS          *
// ********************************

// VARS FOR SCSS CUSTOM ONLY
:root {

  // ********************************
  // *   NEW COLOR QUENTIN ESPRIT   *
  // ********************************

  // TEXT COLORS

  --color-black:                      #2A2D34;
  --color-main-text:                  #494E5A;
  --color-white:                      #FDFFFC;

  // ORANGES

  --color-orange-100:                 #FFCBB8;
  --color-orange-400:                 #FC6D35;
  --color-orange-500:                 #FB5607;
  --color-orange-600:                 #F04E02;
  --color-orange-700:                 #E24700;

  // BLUES

  --color-blue-50:                    #E3F3FF;
  --color-blue-500:                   #38A7FF;
  --color-blue-700:                   #1F76FE;
  --color-blue-900:                   #0248AC;

  // DARKS

  --color-dark-100:                   #C5D4EC;
  --color-dark-500:                   #597095;
  --color-dark-700:                   #3B4F6D;
  --color-dark-900:                   #19293F;

  // GREENS

  --color-green-100:                  #A1E5D3;
  --color-green-300:                  #32BD9C;
  --color-green-500:                  #009D76;
  --color-green-700:                  #00805B;

  // REDS

  --color-red-100:                    #FBCED1;
  --color-red-300:                    #DD7773;
  --color-red-500:                    #E94F37;
  --color-red-700:                    #C93C30;

  // NEUTRALS

  --color-neutral-50:                 #F7F9FB;
  --color-neutral-100:                #EFF1F3;
  --color-neutral-200:                #E5E7EA;
  --color-neutral-300:                #D9D7D5;
  --color-neutral-400:                #B1B3B5;
  --color-neutral-500:                #919395;
  --color-neutral-600:                #696B6D;

  // ICEBERG

  --color-iceberg:                    #D4EEF3;

  // ********************************
  // *   NEW COLOR QUENTIN ESPRIT   *
  // ********************************

  // FONT
  --color-font:               hsl(198, 28%, 25%);

  // SKY
  --color-sky:                hsl(190, 56%, 89%);
  
  // GRADIENT ORANGE
  --color-button-gradient-orange:            linear-gradient(90deg, hsl(21, 90%, 48%) 0%, hsl(23, 96%, 62%) 100%);
  --color-gradient-orange:                   linear-gradient(90deg, hsl(21, 90%, 48%) 0%, hsl(23, 96%, 62%) 100%);
  --color-gradient-orange-reversed:          linear-gradient(90deg, hsl(23, 96%, 62%) 0%, hsl(21, 90%, 48%) 100%);
  --color-gradient-orange-vertical:          linear-gradient(180deg, hsl(21, 90%, 48%) 0%, hsl(23, 96%, 62%) 100%);
  --color-gradient-orange-vertical-reversed: linear-gradient(180deg, hsl(23, 96%, 62%) 0%, hsl(21, 90%, 48%) 100%);

  --color-gradient-dolphin:                   linear-gradient(90deg, hsl(192, 40%, 66%) 0%, hsl(190, 56%, 89%) 100%);
  --color-gradient-dolphin-reversed:          linear-gradient(90deg, hsl(190, 56%, 89%) 0%, hsl(192, 40%, 66%) 100%);
  --color-gradient-dolphin-vertical:          linear-gradient(180deg, hsl(192, 40%, 66%) 0%, hsl(190, 56%, 89%) 100%);
  --color-gradient-dolphin-vertical-reversed: linear-gradient(180deg, hsl(190, 56%, 89%) 0%, hsl(192, 40%, 66%) 100%);

// PRIMARY
  --color-primary-shade:      hsl(205, 97%, 10%);
  --color-primary:            hsl(209, 62%, 16%);
  --color-primary-tint:       hsl(205, 75%, 18%);
  --color-primary-supertint:  hsl(208, 62%, 81%);

// TERTIARY
  --color-tertiary:           hsl(130, 86%, 50%);
  
// SUCCESS
  --color-success-shade:      hsl(125, 41%, 54%);
  --color-success:            hsl(145, 63%, 49%);
  --color-success-tint:       hsl(125, 100%, 77%);
  --color-success-supertint:  hsl(125, 100%, 90%);

// WARNING
  --color-warning-shade:      hsl(37, 100%, 46%);
  --color-warning:            hsl(48, 89%, 50%);
  --color-warning-tint:       hsl(23, 96%, 62%);
  --color-warning-supertint:  hsl(48, 90%, 85%);

// NEW NAMES
  --color-peach:              hsl(41, 100%, 85%);
  --color-orangine:           hsl(23, 96%, 62%);
  --color-hiorange:           hsl(21, 90%, 48%);

// DANGER
  --color-danger-shade:       hsl(357, 73%, 41%);
  --color-danger:             hsl(6, 78%, 57%);
  --color-danger-tint:        hsl(359, 98%, 66%);
  --color-danger-supertint:   hsl(6, 92%, 89%);

// INFO
  --color-info-shade:         hsl(185, 55%, 59%);
  --color-info:               hsl(185, 55%, 73%);
  --color-info-tint:          hsl(185, 55%, 85%);

// WHITE / DARK
  // --color-white:              hsl(0, 0%, 100%);
  --color-dark:               hsl(220, 8%, 15%);
  --color-cypres:             hsl(198, 28%, 25%);

// LIGHT
  --color-light:              hsl(210, 36%, 96%);

// GRAY
  --color-gray-shade:         hsl(0, 0%, 35%);
  --color-gray:               hsl(0, 0%, 50%);
  --color-gray-tint:          hsl(0, 0%, 62%);
  --color-gray-35:            hsl(0, 0%, 40%);
  --color-gray-62:            hsl(0, 0%, 60%);
  --color-gray-74:            hsl(0, 0%, 74%);
  --color-gray-90:            hsl(0, 0%, 96%);

// OVNIS
  --color-oyster:             hsl(212, 33%, 89%);
  --color-bali:               hsl(209, 23%, 60%);
  --color-wedgewood:          hsl(209, 28%, 39%);
  --color-orange-evs:         hsl(21, 90%, 48%);
  --color-green-evs:          hsl(192, 40%, 66%);
  --color-green-moisture:     hsl(201, 29%, 84%);
  --color-blue-evs:           hsl(209, 73%, 93%);
  --color-botticelli-evs:     hsl(202, 29%, 84%);
  --color-light-cyan:         hsl(194, 100%, 95%);
  --color-sea-green:          hsl(185, 83%, 20%);
  --color-ocean:              hsl(209, 62%, 20%);
  
  @each $family, $colors in variables.$colorsMap {
    @each $name, $value in $colors {
      --color-#{$name}: #{$value};
    }
  }
}