@use "variables";
// ********************************
// *     COLORS TEXTS HELPERS     *
// ********************************

.text-black{                          color: var(--color-black)!important}
.text-main-text{                      color: var(--color-main-text)!important}
.text-white{                     color: var(--color-white)!important}
.text-orange-100{                     color: var(--color-orange-100)!important}
.text-orange-400{                     color: var(--color-orange-400)!important}
.text-orange-500{                     color: var(--color-orange-500)!important}
.text-orange-600{                     color: var(--color-orange-600)!important}
.text-orange-700{                     color: var(--color-orange-700)!important}
.text-blue-50{                        color: var(--color-blue-50)!important}
.text-blue-500{                       color: var(--color-blue-500)!important}
.text-blue-700{                       color: var(--color-blue-700)!important}
.text-blue-900{                       color: var(--color-blue-900)!important}
.text-dark-100{                       color: var(--color-dark-100)!important}
.text-dark-500{                       color: var(--color-dark-500)!important}
.text-dark-700{                       color: var(--color-dark-700)!important}
.text-dark-900{                       color: var(--color-dark-900)!important}
.text-green-100{                      color: var(--color-green-100)!important}
.text-green-300{                      color: var(--color-green-300)!important}
.text-green-500{                      color: var(--color-green-500)!important}
.text-red-100{                        color: var(--color-red-100)!important}
.text-red-300{                        color: var(--color-red-300)!important}
.text-red-500{                        color: var(--color-red-500)!important}
.text-red-700{                        color: var(--color-red-700)!important}
.text-iceberg{                        color: var(--color-iceberg)!important}
.text-neutral-50{                     color: var(--color-neutral-50)!important}
.text-neutral-100{                    color: var(--color-neutral-100)!important}
.text-neutral-200{                    color: var(--color-neutral-200)!important}
.text-neutral-300{                    color: var(--color-neutral-300)!important}
.text-neutral-400{                    color: var(--color-neutral-400)!important}
.text-neutral-500{                    color: var(--color-neutral-500)!important}
.text-neutral-600{                    color: var(--color-neutral-600)!important}

.text-font{                    color: var(--color-font)!important}
.text-sky{                     color: var(--color-sky)!important}
.text-primary-shade{           color: var(--color-primary-shade)!important}
.text-primary{                 color: var(--color-primary)!important}
.text-primary-tint{            color: var(--color-primary-tint)!important}
.text-primary-supertint{       color: var(--color-primary-supertint)!important}
.text-tertiary{                color: var(--color-tertiary)!important}
.text-success-shade{           color: var(--color-success-shade)!important}
.text-success{                 color: var(--color-success)!important}
.text-success-tint{            color: var(--color-success-tint)!important}
.text-success-supertint{       color: var(--color-success-supertint)!important}
.text-warning-shade{           color: var(--color-warning-shade)!important}
.text-warning{                 color: var(--color-warning)!important}
.text-warning-tint{            color: var(--color-warning-tint)!important}
.text-warning-supertint{       color: var(--color-warning-supertint)!important}
.text-danger-shade{            color: var(--color-danger-shade)!important}
.text-danger{                  color: var(--color-danger)!important}
.text-danger-tint{             color: var(--color-danger-tint)!important}
.text-danger-supertint{        color: var(--color-danger-supertint)!important}
.text-info-shade{              color: var(--color-info-shade)!important}
.text-info{                    color: var(--color-info)!important}
.text-info-tint{               color: var(--color-info-tint)!important}
.text-white{                   color: var(--color-white)!important}
.text-dark{                    color: var(--color-dark)!important}
.text-black{                   color: var(--color-black)!important}
.text-light{                   color: var(--color-light)!important}
.text-gray-shade{              color: var(--color-gray-shade)!important}
.text-gray{                    color: var(--color-gray)!important}
.text-gray-tint{               color: var(--color-gray-tint)!important}
.text-gray-35{                 color: var(--color-gray-35)!important}
.text-gray-62{                 color: var(--color-gray-62)!important}
.text-gray-74{                 color: var(--color-gray-74)!important}
.text-gray-90{                 color: var(--color-gray-90)!important}
.text-oyster{                  color: var(--color-oyster)!important}
.text-bali{                    color: var(--color-bali)!important}
.text-wedgewood{               color: var(--color-wedgewood)!important}
.text-orange-evs{              color: var(--color-orange-evs)!important}
.text-green-evs{               color: var(--color-green-evs)!important}
.text-blue-evs{                color: var(--color-blue-evs)!important}
.text-botticelli-evs{          color: var(--color-botticelli-evs)!important}
.text-light-cyan{              color: var(--color-light-cyan)!important}
.text-sea-green{               color: var(--color-sea-green)!important}


// ********************************
// *        COLORS BORDERS        *
// ********************************

.border-black{                          border-color: var(--color-black)!important}
.border-main-text{                      border-color: var(--color-main-text)!important}
.border-white{                     border-color: var(--color-white)!important}
.border-orange-100{                     border-color: var(--color-orange-100)!important}
.border-orange-400{                     border-color: var(--color-orange-400)!important}
.border-orange-500{                     border-color: var(--color-orange-500)!important}
.border-orange-600{                     border-color: var(--color-orange-600)!important}
.border-orange-700{                     border-color: var(--color-orange-700)!important}
.border-blue-50{                        border-color: var(--color-blue-50)!important}
.border-blue-500{                       border-color: var(--color-blue-500)!important}
.border-blue-700{                       border-color: var(--color-blue-700)!important}
.border-blue-900{                       border-color: var(--color-blue-900)!important}
.border-dark-100{                       border-color: var(--color-dark-100)!important}
.border-dark-500{                       border-color: var(--color-dark-500)!important}
.border-dark-700{                       border-color: var(--color-dark-700)!important}
.border-dark-900{                       border-color: var(--color-dark-900)!important}
.border-green-100{                      border-color: var(--color-green-100)!important}
.border-green-300{                      border-color: var(--color-green-300)!important}
.border-green-500{                      border-color: var(--color-green-500)!important}
.border-red-100{                        border-color: var(--color-red-100)!important}
.border-red-300{                        border-color: var(--color-red-300)!important}
.border-red-500{                        border-color: var(--color-red-500)!important}
.border-red-700{                        border-color: var(--color-red-700)!important}
.border-iceberg{                        border-color: var(--color-iceberg)!important}
.border-neutral-50{                     border-color: var(--color-neutral-50)!important}
.border-neutral-100{                    border-color: var(--color-neutral-100)!important}
.border-neutral-200{                    border-color: var(--color-neutral-200)!important}
.border-neutral-300{                    border-color: var(--color-neutral-300)!important}
.border-neutral-400{                    border-color: var(--color-neutral-400)!important}
.border-neutral-500{                    border-color: var(--color-neutral-500)!important}
.border-neutral-600{                    border-color: var(--color-neutral-600)!important}

.border-font{                    border-color: var(--color-font)!important}
.border-sky{                     border-color: var(--color-sky)!important}
.border-primary-shade{           border-color: var(--color-primary-shade)!important}
.border-primary{                 border-color: var(--color-primary)!important}
.border-primary-tint{            border-color: var(--color-primary-tint)!important}
.border-primary-supertint{       border-color: var(--color-primary-supertint)!important}
.border-tertiary{                border-color: var(--color-tertiary)!important}
.border-success-shade{           border-color: var(--color-success-shade)!important}
.border-success{                 border-color: var(--color-success)!important}
.border-success-tint{            border-color: var(--color-success-tint)!important}
.border-success-supertint{       border-color: var(--color-success-supertint)!important}
.border-warning-shade{           border-color: var(--color-warning-shade)!important}
.border-warning{                 border-color: var(--color-warning)!important}
.border-warning-tint{            border-color: var(--color-warning-tint)!important}
.border-warning-supertint{       border-color: var(--color-warning-supertint)!important}
.border-danger-shade{            border-color: var(--color-danger-shade)!important}
.border-danger{                  border-color: var(--color-danger)!important}
.border-danger-tint{             border-color: var(--color-danger-tint)!important}
.border-danger-supertint{        border-color: var(--color-danger-supertint)!important}
.border-info-shade{              border-color: var(--color-info-shade)!important}
.border-info{                    border-color: var(--color-info)!important}
.border-info-tint{               border-color: var(--color-info-tint)!important}
.border-white{                   border-color: var(--color-white)!important}
.border-dark{                    border-color: var(--color-dark)!important}
.border-black{                   border-color: var(--color-black)!important}
.border-light{                   border-color: var(--color-light)!important}
.border-gray-shade{              border-color: var(--color-gray-shade)!important}
.border-gray{                    border-color: var(--color-gray)!important}
.border-gray-tint{               border-color: var(--color-gray-tint)!important}
.border-gray-35{                 border-color: var(--color-gray-35)!important}
.border-gray-62{                 border-color: var(--color-gray-62)!important}
.border-gray-74{                 border-color: var(--color-gray-74)!important}
.border-gray-90{                 border-color: var(--color-gray-90)!important}
.border-oyster{                  border-color: var(--color-oyster)!important}
.border-bali{                    border-color: var(--color-bali)!important}
.border-wedgewood{               border-color: var(--color-wedgewood)!important}
.border-orange-evs{              border-color: var(--color-orange-evs)!important}
.border-green-evs{               border-color: var(--color-green-evs)!important}
.border-blue-evs{                border-color: var(--color-blue-evs)!important}
.border-botticelli-evs{          border-color: var(--color-botticelli-evs)!important}
.border-light-cyan{              border-color: var(--color-light-cyan)!important}
.border-sea-green{               border-color: var(--color-sea-green)!important}

.border-left-black{                          border-left-color: var(--color-black)!important}
.border-left-main-text{                      border-left-color: var(--color-main-text)!important}
.border-left-white{                     border-left-color: var(--color-white)!important}
.border-left-orange-100{                     border-left-color: var(--color-orange-100)!important}
.border-left-orange-400{                     border-left-color: var(--color-orange-400)!important}
.border-left-orange-500{                     border-left-color: var(--color-orange-500)!important}
.border-left-orange-600{                     border-left-color: var(--color-orange-600)!important}
.border-left-orange-700{                     border-left-color: var(--color-orange-700)!important}
.border-left-blue-50{                        border-left-color: var(--color-blue-50)!important}
.border-left-blue-500{                       border-left-color: var(--color-blue-500)!important}
.border-left-blue-700{                       border-left-color: var(--color-blue-700)!important}
.border-left-blue-900{                       border-left-color: var(--color-blue-900)!important}
.border-left-dark-100{                       border-left-color: var(--color-dark-100)!important}
.border-left-dark-500{                       border-left-color: var(--color-dark-500)!important}
.border-left-dark-700{                       border-left-color: var(--color-dark-700)!important}
.border-left-dark-900{                       border-left-color: var(--color-dark-900)!important}
.border-left-green-100{                      border-left-color: var(--color-green-100)!important}
.border-left-green-300{                      border-left-color: var(--color-green-300)!important}
.border-left-green-500{                      border-left-color: var(--color-green-500)!important}
.border-left-red-100{                        border-left-color: var(--color-red-100)!important}
.border-left-red-300{                        border-left-color: var(--color-red-300)!important}
.border-left-red-500{                        border-left-color: var(--color-red-500)!important}
.border-left-red-700{                        border-left-color: var(--color-red-700)!important}
.border-left-iceberg{                        border-left-color: var(--color-iceberg)!important}
.border-left-neutral-50{                     border-left-color: var(--color-neutral-50)!important}
.border-left-neutral-100{                    border-left-color: var(--color-neutral-100)!important}
.border-left-neutral-200{                    border-left-color: var(--color-neutral-200)!important}
.border-left-neutral-300{                    border-left-color: var(--color-neutral-300)!important}
.border-left-neutral-400{                    border-left-color: var(--color-neutral-400)!important}
.border-left-neutral-500{                    border-left-color: var(--color-neutral-500)!important}
.border-left-neutral-600{                    border-left-color: var(--color-neutral-600)!important}

.border-left-font{                    border-left-color: var(--color-font)!important}
.border-left-sky{                     border-left-color: var(--color-sky)!important}
.border-left-primary-shade{           border-left-color: var(--color-primary-shade)!important}
.border-left-primary{                 border-left-color: var(--color-primary)!important}
.border-left-primary-tint{            border-left-color: var(--color-primary-tint)!important}
.border-left-primary-supertint{       border-left-color: var(--color-primary-supertint)!important}
.border-left-tertiary{                border-left-color: var(--color-tertiary)!important}
.border-left-success-shade{           border-left-color: var(--color-success-shade)!important}
.border-left-success{                 border-left-color: var(--color-success)!important}
.border-left-success-tint{            border-left-color: var(--color-success-tint)!important}
.border-left-success-supertint{       border-left-color: var(--color-success-supertint)!important}
.border-left-warning-shade{           border-left-color: var(--color-warning-shade)!important}
.border-left-warning{                 border-left-color: var(--color-warning)!important}
.border-left-warning-tint{            border-left-color: var(--color-warning-tint)!important}
.border-left-warning-supertint{       border-left-color: var(--color-warning-supertint)!important}
.border-left-danger-shade{            border-left-color: var(--color-danger-shade)!important}
.border-left-danger{                  border-left-color: var(--color-danger)!important}
.border-left-danger-tint{             border-left-color: var(--color-danger-tint)!important}
.border-left-danger-supertint{        border-left-color: var(--color-danger-supertint)!important}
.border-left-info-shade{              border-left-color: var(--color-info-shade)!important}
.border-left-info{                    border-left-color: var(--color-info)!important}
.border-left-info-tint{               border-left-color: var(--color-info-tint)!important}
.border-left-white{                   border-left-color: var(--color-white)!important}
.border-left-dark{                    border-left-color: var(--color-dark)!important}
.border-left-black{                   border-left-color: var(--color-black)!important}
.border-left-light{                   border-left-color: var(--color-light)!important}
.border-left-gray-shade{              border-left-color: var(--color-gray-shade)!important}
.border-left-gray{                    border-left-color: var(--color-gray)!important}
.border-left-gray-tint{               border-left-color: var(--color-gray-tint)!important}
.border-left-gray-35{                 border-left-color: var(--color-gray-35)!important}
.border-left-gray-62{                 border-left-color: var(--color-gray-62)!important}
.border-left-gray-74{                 border-left-color: var(--color-gray-74)!important}
.border-left-gray-90{                 border-left-color: var(--color-gray-90)!important}
.border-left-oyster{                  border-left-color: var(--color-oyster)!important}
.border-left-bali{                    border-left-color: var(--color-bali)!important}
.border-left-wedgewood{               border-left-color: var(--color-wedgewood)!important}
.border-left-orange-evs{              border-left-color: var(--color-orange-evs)!important}
.border-left-green-evs{               border-left-color: var(--color-green-evs)!important}
.border-left-blue-evs{                border-left-color: var(--color-blue-evs)!important}
.border-left-botticelli-evs{          border-left-color: var(--color-botticelli-evs)!important}
.border-left-light-cyan{              border-left-color: var(--color-light-cyan)!important}
.border-left-sea-green{               border-left-color: var(--color-sea-green)!important}

.border-right-black{                          border-right-color: var(--color-black)!important}
.border-right-main-text{                      border-right-color: var(--color-main-text)!important}
.border-right-white{                     border-right-color: var(--color-white)!important}
.border-right-orange-100{                     border-right-color: var(--color-orange-100)!important}
.border-right-orange-400{                     border-right-color: var(--color-orange-400)!important}
.border-right-orange-500{                     border-right-color: var(--color-orange-500)!important}
.border-right-orange-600{                     border-right-color: var(--color-orange-600)!important}
.border-right-orange-700{                     border-right-color: var(--color-orange-700)!important}
.border-right-blue-50{                        border-right-color: var(--color-blue-50)!important}
.border-right-blue-500{                       border-right-color: var(--color-blue-500)!important}
.border-right-blue-700{                       border-right-color: var(--color-blue-700)!important}
.border-right-blue-900{                       border-right-color: var(--color-blue-900)!important}
.border-right-dark-100{                       border-right-color: var(--color-dark-100)!important}
.border-right-dark-500{                       border-right-color: var(--color-dark-500)!important}
.border-right-dark-700{                       border-right-color: var(--color-dark-700)!important}
.border-right-dark-900{                       border-right-color: var(--color-dark-900)!important}
.border-right-green-100{                      border-right-color: var(--color-green-100)!important}
.border-right-green-300{                      border-right-color: var(--color-green-300)!important}
.border-right-green-500{                      border-right-color: var(--color-green-500)!important}
.border-right-red-100{                        border-right-color: var(--color-red-100)!important}
.border-right-red-300{                        border-right-color: var(--color-red-300)!important}
.border-right-red-500{                        border-right-color: var(--color-red-500)!important}
.border-right-red-700{                        border-right-color: var(--color-red-700)!important}
.border-right-iceberg{                        border-right-color: var(--color-iceberg)!important}
.border-right-neutral-50{                     border-right-color: var(--color-neutral-50)!important}
.border-right-neutral-100{                    border-right-color: var(--color-neutral-100)!important}
.border-right-neutral-200{                    border-right-color: var(--color-neutral-200)!important}
.border-right-neutral-300{                    border-right-color: var(--color-neutral-300)!important}
.border-right-neutral-400{                    border-right-color: var(--color-neutral-400)!important}
.border-right-neutral-500{                    border-right-color: var(--color-neutral-500)!important}
.border-right-neutral-600{                    border-right-color: var(--color-neutral-600)!important}

.border-right-font{                    border-right-color: var(--color-font)!important}
.border-right-sky{                     border-right-color: var(--color-sky)!important}
.border-right-primary-shade{           border-right-color: var(--color-primary-shade)!important}
.border-right-primary{                 border-right-color: var(--color-primary)!important}
.border-right-primary-tint{            border-right-color: var(--color-primary-tint)!important}
.border-right-primary-supertint{       border-right-color: var(--color-primary-supertint)!important}
.border-right-tertiary{                border-right-color: var(--color-tertiary)!important}
.border-right-success-shade{           border-right-color: var(--color-success-shade)!important}
.border-right-success{                 border-right-color: var(--color-success)!important}
.border-right-success-tint{            border-right-color: var(--color-success-tint)!important}
.border-right-success-supertint{       border-right-color: var(--color-success-supertint)!important}
.border-right-warning-shade{           border-right-color: var(--color-warning-shade)!important}
.border-right-warning{                 border-right-color: var(--color-warning)!important}
.border-right-warning-tint{            border-right-color: var(--color-warning-tint)!important}
.border-right-warning-supertint{       border-right-color: var(--color-warning-supertint)!important}
.border-right-danger-shade{            border-right-color: var(--color-danger-shade)!important}
.border-right-danger{                  border-right-color: var(--color-danger)!important}
.border-right-danger-tint{             border-right-color: var(--color-danger-tint)!important}
.border-right-danger-supertint{        border-right-color: var(--color-danger-supertint)!important}
.border-right-info-shade{              border-right-color: var(--color-info-shade)!important}
.border-right-info{                    border-right-color: var(--color-info)!important}
.border-right-info-tint{               border-right-color: var(--color-info-tint)!important}
.border-right-white{                   border-right-color: var(--color-white)!important}
.border-right-dark{                    border-right-color: var(--color-dark)!important}
.border-right-black{                   border-right-color: var(--color-black)!important}
.border-right-light{                   border-right-color: var(--color-light)!important}
.border-right-gray-shade{              border-right-color: var(--color-gray-shade)!important}
.border-right-gray{                    border-right-color: var(--color-gray)!important}
.border-right-gray-tint{               border-right-color: var(--color-gray-tint)!important}
.border-right-gray-35{                 border-right-color: var(--color-gray-35)!important}
.border-right-gray-62{                 border-right-color: var(--color-gray-62)!important}
.border-right-gray-74{                 border-right-color: var(--color-gray-74)!important}
.border-right-gray-90{                 border-right-color: var(--color-gray-90)!important}
.border-right-oyster{                  border-right-color: var(--color-oyster)!important}
.border-right-bali{                    border-right-color: var(--color-bali)!important}
.border-right-wedgewood{               border-right-color: var(--color-wedgewood)!important}
.border-right-orange-evs{              border-right-color: var(--color-orange-evs)!important}
.border-right-green-evs{               border-right-color: var(--color-green-evs)!important}
.border-right-blue-evs{                border-right-color: var(--color-blue-evs)!important}
.border-right-botticelli-evs{          border-right-color: var(--color-botticelli-evs)!important}
.border-right-light-cyan{              border-right-color: var(--color-light-cyan)!important}
.border-right-sea-green{               border-right-color: var(--color-sea-green)!important}

.border-top-black{                          border-top-color: var(--color-black)!important}
.border-top-main-text{                      border-top-color: var(--color-main-text)!important}
.border-top-white{                     border-top-color: var(--color-white)!important}
.border-top-orange-100{                     border-top-color: var(--color-orange-100)!important}
.border-top-orange-400{                     border-top-color: var(--color-orange-400)!important}
.border-top-orange-500{                     border-top-color: var(--color-orange-500)!important}
.border-top-orange-600{                     border-top-color: var(--color-orange-600)!important}
.border-top-orange-700{                     border-top-color: var(--color-orange-700)!important}
.border-top-blue-50{                        border-top-color: var(--color-blue-50)!important}
.border-top-blue-500{                       border-top-color: var(--color-blue-500)!important}
.border-top-blue-700{                       border-top-color: var(--color-blue-700)!important}
.border-top-blue-900{                       border-top-color: var(--color-blue-900)!important}
.border-top-dark-100{                       border-top-color: var(--color-dark-100)!important}
.border-top-dark-500{                       border-top-color: var(--color-dark-500)!important}
.border-top-dark-700{                       border-top-color: var(--color-dark-700)!important}
.border-top-dark-900{                       border-top-color: var(--color-dark-900)!important}
.border-top-green-100{                      border-top-color: var(--color-green-100)!important}
.border-top-green-300{                      border-top-color: var(--color-green-300)!important}
.border-top-green-500{                      border-top-color: var(--color-green-500)!important}
.border-top-red-100{                        border-top-color: var(--color-red-100)!important}
.border-top-red-300{                        border-top-color: var(--color-red-300)!important}
.border-top-red-500{                        border-top-color: var(--color-red-500)!important}
.border-top-red-700{                        border-top-color: var(--color-red-700)!important}
.border-top-iceberg{                        border-top-color: var(--color-iceberg)!important}
.border-top-neutral-50{                     border-top-color: var(--color-neutral-50)!important}
.border-top-neutral-100{                    border-top-color: var(--color-neutral-100)!important}
.border-top-neutral-200{                    border-top-color: var(--color-neutral-200)!important}
.border-top-neutral-300{                    border-top-color: var(--color-neutral-300)!important}
.border-top-neutral-400{                    border-top-color: var(--color-neutral-400)!important}
.border-top-neutral-500{                    border-top-color: var(--color-neutral-500)!important}
.border-top-neutral-600{                    border-top-color: var(--color-neutral-600)!important}

.border-top-font{                    border-top-color: var(--color-font)!important}
.border-top-sky{                     border-top-color: var(--color-sky)!important}
.border-top-primary-shade{           border-top-color: var(--color-primary-shade)!important}
.border-top-primary{                 border-top-color: var(--color-primary)!important}
.border-top-primary-tint{            border-top-color: var(--color-primary-tint)!important}
.border-top-primary-supertint{       border-top-color: var(--color-primary-supertint)!important}
.border-top-tertiary{                border-top-color: var(--color-tertiary)!important}
.border-top-success-shade{           border-top-color: var(--color-success-shade)!important}
.border-top-success{                 border-top-color: var(--color-success)!important}
.border-top-success-tint{            border-top-color: var(--color-success-tint)!important}
.border-top-success-supertint{       border-top-color: var(--color-success-supertint)!important}
.border-top-warning-shade{           border-top-color: var(--color-warning-shade)!important}
.border-top-warning{                 border-top-color: var(--color-warning)!important}
.border-top-warning-tint{            border-top-color: var(--color-warning-tint)!important}
.border-top-warning-supertint{       border-top-color: var(--color-warning-supertint)!important}
.border-top-danger-shade{            border-top-color: var(--color-danger-shade)!important}
.border-top-danger{                  border-top-color: var(--color-danger)!important}
.border-top-danger-tint{             border-top-color: var(--color-danger-tint)!important}
.border-top-danger-supertint{        border-top-color: var(--color-danger-supertint)!important}
.border-top-info-shade{              border-top-color: var(--color-info-shade)!important}
.border-top-info{                    border-top-color: var(--color-info)!important}
.border-top-info-tint{               border-top-color: var(--color-info-tint)!important}
.border-top-white{                   border-top-color: var(--color-white)!important}
.border-top-dark{                    border-top-color: var(--color-dark)!important}
.border-top-black{                   border-top-color: var(--color-black)!important}
.border-top-light{                   border-top-color: var(--color-light)!important}
.border-top-gray-shade{              border-top-color: var(--color-gray-shade)!important}
.border-top-gray{                    border-top-color: var(--color-gray)!important}
.border-top-gray-tint{               border-top-color: var(--color-gray-tint)!important}
.border-top-gray-35{                 border-top-color: var(--color-gray-35)!important}
.border-top-gray-62{                 border-top-color: var(--color-gray-62)!important}
.border-top-gray-74{                 border-top-color: var(--color-gray-74)!important}
.border-top-gray-90{                 border-top-color: var(--color-gray-90)!important}
.border-top-oyster{                  border-top-color: var(--color-oyster)!important}
.border-top-bali{                    border-top-color: var(--color-bali)!important}
.border-top-wedgewood{               border-top-color: var(--color-wedgewood)!important}
.border-top-orange-evs{              border-top-color: var(--color-orange-evs)!important}
.border-top-green-evs{               border-top-color: var(--color-green-evs)!important}
.border-top-blue-evs{                border-top-color: var(--color-blue-evs)!important}
.border-top-botticelli-evs{          border-top-color: var(--color-botticelli-evs)!important}
.border-top-light-cyan{              border-top-color: var(--color-light-cyan)!important}
.border-top-sea-green{               border-top-color: var(--color-sea-green)!important}

.border-bottom-black{                          border-bottom-color: var(--color-black)!important}
.border-bottom-main-text{                      border-bottom-color: var(--color-main-text)!important}
.border-bottom-white{                     border-bottom-color: var(--color-white)!important}
.border-bottom-orange-100{                     border-bottom-color: var(--color-orange-100)!important}
.border-bottom-orange-400{                     border-bottom-color: var(--color-orange-400)!important}
.border-bottom-orange-500{                     border-bottom-color: var(--color-orange-500)!important}
.border-bottom-orange-600{                     border-bottom-color: var(--color-orange-600)!important}
.border-bottom-orange-700{                     border-bottom-color: var(--color-orange-700)!important}
.border-bottom-blue-50{                        border-bottom-color: var(--color-blue-50)!important}
.border-bottom-blue-500{                       border-bottom-color: var(--color-blue-500)!important}
.border-bottom-blue-700{                       border-bottom-color: var(--color-blue-700)!important}
.border-bottom-blue-900{                       border-bottom-color: var(--color-blue-900)!important}
.border-bottom-dark-100{                       border-bottom-color: var(--color-dark-100)!important}
.border-bottom-dark-500{                       border-bottom-color: var(--color-dark-500)!important}
.border-bottom-dark-700{                       border-bottom-color: var(--color-dark-700)!important}
.border-bottom-dark-900{                       border-bottom-color: var(--color-dark-900)!important}
.border-bottom-green-100{                      border-bottom-color: var(--color-green-100)!important}
.border-bottom-green-300{                      border-bottom-color: var(--color-green-300)!important}
.border-bottom-green-500{                      border-bottom-color: var(--color-green-500)!important}
.border-bottom-red-100{                        border-bottom-color: var(--color-red-100)!important}
.border-bottom-red-300{                        border-bottom-color: var(--color-red-300)!important}
.border-bottom-red-500{                        border-bottom-color: var(--color-red-500)!important}
.border-bottom-red-700{                        border-bottom-color: var(--color-red-700)!important}
.border-bottom-iceberg{                        border-bottom-color: var(--color-iceberg)!important}
.border-bottom-neutral-50{                     border-bottom-color: var(--color-neutral-50)!important}
.border-bottom-neutral-100{                    border-bottom-color: var(--color-neutral-100)!important}
.border-bottom-neutral-200{                    border-bottom-color: var(--color-neutral-200)!important}
.border-bottom-neutral-300{                    border-bottom-color: var(--color-neutral-300)!important}
.border-bottom-neutral-400{                    border-bottom-color: var(--color-neutral-400)!important}
.border-bottom-neutral-500{                    border-bottom-color: var(--color-neutral-500)!important}
.border-bottom-neutral-600{                    border-bottom-color: var(--color-neutral-600)!important}

.border-bottom-font{                    border-bottom-color: var(--color-font)!important}
.border-bottom-sky{                     border-bottom-color: var(--color-sky)!important}
.border-bottom-primary-shade{           border-bottom-color: var(--color-primary-shade)!important}
.border-bottom-primary{                 border-bottom-color: var(--color-primary)!important}
.border-bottom-primary-tint{            border-bottom-color: var(--color-primary-tint)!important}
.border-bottom-primary-supertint{       border-bottom-color: var(--color-primary-supertint)!important}
.border-bottom-tertiary{                border-bottom-color: var(--color-tertiary)!important}
.border-bottom-success-shade{           border-bottom-color: var(--color-success-shade)!important}
.border-bottom-success{                 border-bottom-color: var(--color-success)!important}
.border-bottom-success-tint{            border-bottom-color: var(--color-success-tint)!important}
.border-bottom-success-supertint{       border-bottom-color: var(--color-success-supertint)!important}
.border-bottom-warning-shade{           border-bottom-color: var(--color-warning-shade)!important}
.border-bottom-warning{                 border-bottom-color: var(--color-warning)!important}
.border-bottom-warning-tint{            border-bottom-color: var(--color-warning-tint)!important}
.border-bottom-warning-supertint{       border-bottom-color: var(--color-warning-supertint)!important}
.border-bottom-danger-shade{            border-bottom-color: var(--color-danger-shade)!important}
.border-bottom-danger{                  border-bottom-color: var(--color-danger)!important}
.border-bottom-danger-tint{             border-bottom-color: var(--color-danger-tint)!important}
.border-bottom-danger-supertint{        border-bottom-color: var(--color-danger-supertint)!important}
.border-bottom-info-shade{              border-bottom-color: var(--color-info-shade)!important}
.border-bottom-info{                    border-bottom-color: var(--color-info)!important}
.border-bottom-info-tint{               border-bottom-color: var(--color-info-tint)!important}
.border-bottom-white{                   border-bottom-color: var(--color-white)!important}
.border-bottom-dark{                    border-bottom-color: var(--color-dark)!important}
.border-bottom-black{                   border-bottom-color: var(--color-black)!important}
.border-bottom-light{                   border-bottom-color: var(--color-light)!important}
.border-bottom-gray-shade{              border-bottom-color: var(--color-gray-shade)!important}
.border-bottom-gray{                    border-bottom-color: var(--color-gray)!important}
.border-bottom-gray-tint{               border-bottom-color: var(--color-gray-tint)!important}
.border-bottom-gray-35{                 border-bottom-color: var(--color-gray-35)!important}
.border-bottom-gray-62{                 border-bottom-color: var(--color-gray-62)!important}
.border-bottom-gray-74{                 border-bottom-color: var(--color-gray-74)!important}
.border-bottom-gray-90{                 border-bottom-color: var(--color-gray-90)!important}
.border-bottom-oyster{                  border-bottom-color: var(--color-oyster)!important}
.border-bottom-bali{                    border-bottom-color: var(--color-bali)!important}
.border-bottom-wedgewood{               border-bottom-color: var(--color-wedgewood)!important}
.border-bottom-orange-evs{              border-bottom-color: var(--color-orange-evs)!important}
.border-bottom-green-evs{               border-bottom-color: var(--color-green-evs)!important}
.border-bottom-blue-evs{                border-bottom-color: var(--color-blue-evs)!important}
.border-bottom-botticelli-evs{          border-bottom-color: var(--color-botticelli-evs)!important}
.border-bottom-light-cyan{              border-bottom-color: var(--color-light-cyan)!important}
.border-bottom-sea-green{               border-bottom-color: var(--color-sea-green)!important}


// ********************************
// *      COLORS BACKGROUNDS      *
// ********************************


.bg-transparent{             background: transparent!important}

.bg-black{                          background: var(--color-black)!important}
.bg-main-text{                      background: var(--color-main-text)!important}
.bg-white{                     background: var(--color-white)!important}
.bg-orange-100{                     background: var(--color-orange-100)!important}
.bg-orange-400{                     background: var(--color-orange-400)!important}
.bg-orange-500{                     background: var(--color-orange-500)!important}
.bg-orange-600{                     background: var(--color-orange-600)!important}
.bg-orange-700{                     background: var(--color-orange-700)!important}
.bg-blue-50{                        background: var(--color-blue-50)!important}
.bg-blue-500{                       background: var(--color-blue-500)!important}
.bg-blue-700{                       background: var(--color-blue-700)!important}
.bg-blue-900{                       background: var(--color-blue-900)!important}
.bg-dark-100{                       background: var(--color-dark-100)!important}
.bg-dark-500{                       background: var(--color-dark-500)!important}
.bg-dark-700{                       background: var(--color-dark-700)!important}
.bg-dark-900{                       background: var(--color-dark-900)!important}
.bg-green-100{                      background: var(--color-green-100)!important}
.bg-green-300{                      background: var(--color-green-300)!important}
.bg-green-500{                      background: var(--color-green-500)!important}
.bg-red-100{                        background: var(--color-red-100)!important}
.bg-red-300{                        background: var(--color-red-300)!important}
.bg-red-500{                        background: var(--color-red-500)!important}
.bg-red-700{                        background: var(--color-red-700)!important}
.bg-iceberg{                        background: var(--color-iceberg)!important}
.bg-neutral-50{                     background: var(--color-neutral-50)!important}
.bg-neutral-100{                    background: var(--color-neutral-100)!important}
.bg-neutral-200{                    background: var(--color-neutral-200)!important}
.bg-neutral-300{                    background: var(--color-neutral-300)!important}
.bg-neutral-400{                    background: var(--color-neutral-400)!important}
.bg-neutral-500{                    background: var(--color-neutral-500)!important}
.bg-neutral-600{                    background: var(--color-neutral-600)!important}

.bg-font{                    background: var(--color-font)!important}
.bg-gradient-orange{         background: var(--color-gradient-orange)!important}
.bg-sky{                     background: var(--color-sky)!important}
.bg-primary-shade{           background: var(--color-primary-shade)!important}
.bg-primary{                 background: var(--color-primary)!important}
.bg-primary-tint{            background: var(--color-primary-tint)!important}
.bg-primary-supertint{       background: var(--color-primary-supertint)!important}
.bg-tertiary{                background: var(--color-tertiary)!important}
.bg-success-shade{           background: var(--color-success-shade)!important}
.bg-success{                 background: var(--color-success)!important}
.bg-success-tint{            background: var(--color-success-tint)!important}
.bg-success-supertint{       background: var(--color-success-supertint)!important}
.bg-warning-shade{           background: var(--color-warning-shade)!important}
.bg-warning{                 background: var(--color-warning)!important}
.bg-warning-tint{            background: var(--color-warning-tint)!important}
.bg-warning-supertint{       background: var(--color-warning-supertint)!important}
.bg-danger-shade{            background: var(--color-danger-shade)!important}
.bg-danger{                  background: var(--color-danger)!important}
.bg-danger-tint{             background: var(--color-danger-tint)!important}
.bg-danger-supertint{        background: var(--color-danger-supertint)!important}
.bg-info-shade{              background: var(--color-info-shade)!important}
.bg-info{                    background: var(--color-info)!important}
.bg-info-tint{               background: var(--color-info-tint)!important}
.bg-white{                   background: var(--color-white)!important}
.bg-dark{                    background: var(--color-dark)!important}
.bg-black{                   background: var(--color-black)!important}
.bg-light{                   background: var(--color-light)!important}
.bg-gray-shade{              background: var(--color-gray-shade)!important}
.bg-gray{                    background: var(--color-gray)!important}
.bg-gray-tint{               background: var(--color-gray-tint)!important}
.bg-gray-35{                 background: var(--color-gray-35)!important}
.bg-gray-62{                 background: var(--color-gray-62)!important}
.bg-gray-74{                 background: var(--color-gray-74)!important}
.bg-gray-90{                 background: var(--color-gray-90)!important}
.bg-oyster{                  background: var(--color-oyster)!important}
.bg-bali{                    background: var(--color-bali)!important}
.bg-wedgewood{               background: var(--color-wedgewood)!important}
.bg-orange-evs{              background: var(--color-orange-evs)!important}
.bg-green-evs{               background: var(--color-green-evs)!important}
.bg-blue-evs{                background: var(--color-blue-evs)!important}
.bg-botticelli-evs{          background: var(--color-botticelli-evs)!important}
.bg-light-cyan{              background: var(--color-light-cyan)!important}
.bg-sea-green{               background: var(--color-sea-green)!important}

// GENERATE COLORS CLASS HELPERS FROM COLORSMAP
@each $family, $colors in variables.$colorsMap {
  @each $name, $value in $colors {
    // COLORS TEXTS
    .text-#{$name} {
      color: $value !important;
    }

    // COLORS BACKGROUNDS
    .bg-color-#{$name} {
      background-color: $value !important;
    }

    .bg-#{$name} {
      background: $value !important;
    }

    // COLORS BORDERS
    .border-#{$name} {
      border-color: $value !important;
    }
    .border-top-#{$name} {
      border-top-color: $value !important;
    }
    .border-bottom-#{$name} {
      border-bottom-color: $value !important;
    }
    .border-left-#{$name} {
      border-left-color: $value !important;
    }
    .border-right-#{$name} {
      border-right-color: $value !important;
    }

    @for $i from 0 through 100 {
      // COLORS TEXTS WITH OPACITY
      .text-#{$name}-op-#{$i} {
        color: rgba($value, calc($i / 100)) !important;
      }

      // COLORS BACKGROUNDS WITH OPACITY
      .bg-color-#{$name}-op-#{$i} {
        background-color: rgba($value, calc($i / 100)) !important;
      }

      .bg-#{$name}-op-#{$i} {
        background: rgba($value, calc($i / 100)) !important;
      }

      // COLORS BORDERS WITH OPACITY
      .border-#{$name}-op-#{$i} {
        border-color: rgba($value, calc($i / 100)) !important;
      }
      .border-top-#{$name}-op-#{$i} {
        border-top-color: rgba($value, calc($i / 100)) !important;
      }
      .border-bottom-#{$name}-op-#{$i} {
        border-bottom-color: rgba($value, calc($i / 100)) !important;
      }
      .border-left-#{$name}-op-#{$i} {
        border-left-color: rgba($value, calc($i / 100)) !important;
      }
      .border-right-#{$name}-op-#{$i} {
        border-right-color: rgba($value, calc($i / 100)) !important;
      }
    }
  }
}

// ********************************
// *    BACKGROUND TRANSPARENT    *
// ********************************
//
.bg-color-transparent {
  background-color: transparent !important;
}
.bg-transparent {
  background: transparent !important;
}


// ********************************
// *           POSITION           *
// ********************************
.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.block-right {
  display: block;
  margin-left: auto;
  margin-right: 0;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear-float {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  clear: both;
}


// ********************************
// *       CONTENT-POSITION       *
// ********************************
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.absolute-top {
  top: 0;
}
.absolute-top-center {
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.absolute-top-right {
  top: 0;
  right: 0;
}
.absolute-right {
  right: 0;
}
.absolute-bottom {
  bottom: 0;
}
.absolute-bottom-center {
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.absolute-bottom-right {
  bottom: 0;
  right: 0;
}
.absolute-left {
  left: 0;
}


// ********************************
// *       CONTENT-POSITION       *
// ********************************
.content-center-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vertical-center {
  display: flex;
  align-items: center;
}
.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.content-center-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content-bottom-left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.content-bottom-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}
.content-bottom-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
// inline
.inline-content-center-left {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  vertical-align: top;
}
.inline-content-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: top;
}
.inline-content-center-right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: top;
}
.inline-content-bottom-left {
  display: inline-flex; 
  align-items: flex-end;
  justify-content: flex-start;
  vertical-align: top;
}
.inline-content-bottom-center {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  vertical-align: top;
}
.inline-content-bottom-right {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  vertical-align: top;
}
@for $i from 0 through 100 {
  .top-#{$i*5} { top: 5px*$i; }
  .right-#{$i*5} { right: 5px*$i; }
  .bottom-#{$i*5} { bottom: 5px*$i; }
  .left-#{$i*5} { left: 5px*$i; }
}


// ********************************
// *            DISPLAY           *
// ********************************

.display-none {
  display: none !important;
}
.display-block {
  display: block !important;
}
.display-flex {
  display: flex !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row;
}
.flex-row-reverse {
  display: flex !important;
  flex-direction: row-reverse;
}
.flex-column {
  display: flex !important;
  flex-direction: column;
}
.flex-column-reverse {
  display: flex !important;
  flex-direction: column-reverse;
}
.space-around {
  display: flex !important;
  justify-content: space-around;
}
.space-between {
  display: flex !important;
  justify-content: space-between;
}
.display-inline {
  display: inline !important;
  vertical-align: top;
}
.inline-block {
  display: inline-block !important;
  vertical-align: top;
}
.inline-flex {
  display: inline-flex !important;
}


// ********************************
// *            SIZING            *
// ********************************

@for $i from 0 through 500 {
  // square pixel
  .square-#{5*$i} {
    width: 5px*$i;
    height: 5px*$i;
    overflow: hidden;
  }
  //rectangle pixel
  .rectangle-#{5*$i} {
    width: 100%;
    height: 5px*$i;
    overflow: hidden;
  }
  .circle-#{5*$i} {
    width: 5px*$i;
    height: 5px*$i;
    border-radius: 50%;
    overflow: hidden;
  }
  // width pixel
  .wpi-#{5*$i} {
    width: 5px*$i;
    overflow: hidden;
  }
  // height pixel
  .hpi-#{5*$i} {
    height: 5px*$i;
    overflow: hidden;
  }
}

@for $i from 0 through 100 {
  // width percent
  .wpe-#{1*$i} {
    width: 1%*$i;
  }
}

  
// ********************************
// *            SPACING           *
// ********************************

@for $i from 0 through 100 {
  // PADDINGS  
  .padding-#{1*$i} {
    padding: 1px*$i !important;
  }
  .padding-h-#{1*$i} {
    padding-left: 1px*$i !important;
    padding-right: 1px*$i !important;
  }
  .padding-v-#{1*$i} {
    padding-top: 1px*$i !important;
    padding-bottom: 1px*$i !important;
  }
  .padding-top-#{1*$i} {
    padding-top: 1px*$i !important;
  }
  .padding-right-#{1*$i} {
    padding-right: 1px*$i !important;
  }
  .padding-bottom-#{1*$i} {
    padding-bottom: 1px*$i !important;
  }
  .padding-left-#{1*$i} {
    padding-left: 1px*$i !important;
  }
  // MARGINS
  .margin-#{1*$i} {
    margin: 1px*$i !important;
  }
  .margin-h-#{1*$i} {
    margin-left: 1px*$i !important;
    margin-right: 1px*$i !important;
  }
  .margin-v-#{1*$i} {
    margin-top: 1px*$i !important;
    margin-bottom: 1px*$i !important;
  }
  .margin-top-#{1*$i} {
    margin-top: 1px*$i !important;
  }
  .margin-right-#{1*$i} {
    margin-right: 1px*$i !important;
  }
  .margin-bottom-#{1*$i} {
    margin-bottom: 1px*$i !important;
  }
  .margin-left-#{1*$i} {
    margin-left: 1px*$i !important;
  }
}


// ********************************
// *           BORDERS            *
// ********************************

@for $i from 0 through 30 {
  .border-#{$i} {
    border-width: 1px*$i;
    border-style: solid;
  }  
  .border-top-#{$i} {
    border-top-width: 1px*$i;
    border-style: solid;
  }
  .border-right-#{$i} {
    border-right-width: 1px*$i;
    border-style: solid;
  }
  .border-bottom-#{$i} {
    border-bottom-width: 1px*$i;
    border-style: solid;
  }
  .border-left-#{$i} {
    border-left-width: 1px*$i;
    border-style: solid;
  }
}

@for $i from 0 through 100 {
  .border-radius-#{$i} {
    border-radius: 1px*$i;
  }  
}


// ********************************
// *          TYPOGRAPHY          *
// ********************************

.normal {font-style: normal !important}
.italic {font-style: italic !important}
.capitalize {text-transform: capitalize !important}
.lowercase {text-transform: lowercase !important}
.uppercase {text-transform: uppercase !important}
.underline {text-decoration: underline !important}
.line-through {text-decoration:line-through !important}
.shadow-bold {font-weight: 700 !important} // old version to prevent font size changing
.bold-400 { font-weight: 400 !important}
.bold-500 { font-weight: 500 !important}
.bold { font-weight: 700 !important}
.bolder { font-weight: 700 !important}
.text-left {text-align: left !important}
.text-center {text-align: center !important}
.text-right {text-align: right !important}
.text-justify {text-align: justify !important}
.text-nowrap {white-space: nowrap !important}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.xsmall-text {
  font-size: 12px !important;
  font-weight: normal !important;
}
.small-text {
  font-size: 14px !important;
  font-weight: normal !important;
}
.big-text {
  font-size: 140% !important;
  font-weight: 500 !important;
}


// ********************************
// *             TABLE            *
// ********************************

.table-condensed {
  width: initial;
  max-width: initial;
  td, th {
    padding: 4px;
    line-height: 1;
  }
}
.table-centered {
  th, td {
    vertical-align: middle;
    text-align: center;
  }
}
.table-bordered {
  border: 1px solid var(--color-gray);
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid var(--color-gray);
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}
.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: var(--color-light);
  }
}
.table-hover {
  > tbody > tr:hover {
    background-color: var(--color-light);
  }
}

// ********************************
// *       RESPONSIVE-HIDDEN      *
// ********************************

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.invisible {
  visibility: hidden !important;
  pointer-events: none !important;
}

.mobile-only {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.tablet-only {
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 1025px) {
    display: none;
  }
}

.desktop-only {
  @media (max-width: 1024px) {
    display: none;
  }
}


// ********************************
// *            ACTIONS           *
// ********************************

.unselectable {
  cursor: default !important;
  user-select: none !important;
}

.selectable {
  cursor: text !important;
}

.clickable {
  cursor: pointer !important;
  transition: none !important;
  pointer-events: initial !important;
}

.clickable:active {
  transform: scale(.992)!important;
  @media (max-width: 1024px) {
    transform: scale(.98)!important;
  }
  @media (max-width: 767px) {
    transform: scale(.96)!important;
  }
}

.small-clickable {
  transition: none !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.small-clickable:active {
  transition-duration: .1s!important;
  transform: scale(.92)!important;
}

.clickable-safari {
  cursor: pointer !important;
  transition: none !important;
  pointer-events: initial !important;
}

.clickable-safari:active {
  transform: scale(.992)!important;
  @media (max-width: 1024px) {
    transform: scale(.98)!important;
  }
  @media (max-width: 767px) {
    transform: scale(.96)!important;
  }
  @media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) {
    clip-path: content-box;
  }
}

.small-clickable-safari {
  transition: none !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.small-clickable-safari:active {
  transition-duration: .1s!important;
  transform: scale(.92)!important;
  @media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) {
    clip-path: content-box;
  }
}

.unclickable {
  cursor: default !important;
}

.reachable {
  pointer-events: initial !important;
}

.unreachable {
  pointer-events: none !important;
}


// ********************************
// *            LOADERS           *
// ********************************

.spinner-loading {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.spinner-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 4px;
  border: 2px solid var(--color-gray-tint);
  border-radius: 50%;
  animation: spinner-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-gray-tint) transparent transparent transparent;
}
.spinner-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner-loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}