@use 'utilities';
.evs-card-ui {
  position: relative;
  display: block;
  border-radius: 16px;
  padding: 16px;
  background: var(--color-white);
  margin-bottom: 8px;
  user-select: none;
  overflow: hidden;

  .card-content {
    width: calc(100% - 16px);
  }
}

.evs-card {
  position: relative;
  display: block;
  // border-radius: 8px;
  border-radius: 16px;
  padding: 16px;
  background: var(--color-white);
  margin-bottom: 8px;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  @extend .clickable;

  .card-content {
    width: calc(100% - 16px);
  }
}

.evs-card.light, .evs-card-ui.light {
  border: 2px solid var(--color-neutral-300);
}

.evs-card-locked {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.evs-card-ui:last-child,
.evs-card:last-child {
  margin-bottom: 0;
}

card-comp {
  display: block;
  margin-bottom: 8px;
}

card-comp:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 425px) {
  card-comp {
    margin-bottom: 8px;
  }
}
