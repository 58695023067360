.evs_tooltip_bg {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000cc;
  user-select: none;
}

.evs_tooltip {
  font-family: var(--ion-font-family);
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: 2px solid rgba(229, 229, 229, 1);
  padding: 8px;
  position: absolute;
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.evs_usertour {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  .evs_ut_header {
    background-color: #f7f7f7;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid #ebebeb;
  }
  .evs_ut_header,
  .evs_ut_content,
  .evs_ut_footer {
    padding: 10px 14px;
  }
  .evs_btn:first-child {
    margin-right: 10px;
  }
  .evs_btn:disabled {
    opacity: 0.65;
  }
  .evs_btn {
    display: inline-block;
    color: var(--color-white);
    background-color: #007bff;
    border-color: #007bff;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .evs_btStop {
    float: right;
  }
}

.myTable {
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.4);
  border: 1px solid var(--color-gray);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
  th,
  td {
    border: 1px solid var(--color-gray);
    padding: 8px;
  }
}

.tooltip-card-rounded {
  display: block;
  box-shadow: 0 2px 6px 0 hsl(209, 23%, 60%);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1em;
  background: hsl(0, 0%, 100%);
}

// ************************
// *  PURE CSS PIE TIMER  *
// ************************

$animation-duration-tooltip: 5s;
$size-tooltip: 16px; /* Any unit */
$background-color-tooltip: red; /* No gradients */
$demo-content-display-tooltip: none; /* Set "block" to see we can make content on the spinner and watch it rotates */

.wrapper {
  float: right;
  width: $size-tooltip;
  height: $size-tooltip;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  opacity: 0.65;
  background: #f7f7f7;
  .pie {
    width: 50%;
    height: 100%;
    transform-origin: 100% 50%;
    position: absolute;
    background: $background-color-tooltip;
  }

  .spinner {
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    z-index: 200;
    border-right: none;
    animation: rota $animation-duration-tooltip ease-in-out forwards;
  }

  .spinner:after {
    position: absolute;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    content: '';
    display: $demo-content-display-tooltip;
  }

  .filler {
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    left: 50%;
    opacity: 0;
    z-index: 100;
    animation: fill $animation-duration-tooltip steps(1, end) forwards;
    border-left: none;
  }

  .mask {
    width: 50%;
    height: 100%;
    position: absolute;
    background: inherit;
    opacity: 1;
    z-index: 300;
    animation: mask $animation-duration-tooltip steps(1, end) forwards;
  }
}

@keyframes rota {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mask {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

@keyframes fill {
  0% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

.evs_ut_highlight {
  box-shadow: 0 0 16px 8px rgba(255, 255, 255, 0.8);
}
